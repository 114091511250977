import store from '../store/index'
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Authentication/Login'),
    beforeEnter(to, from, next) {
      if (store.getters.isLogged) {
        if (!store.getters.isAdmin) {
          store.commit('logout')

          return next({ name: 'Login' })
        }

        return next({ name: 'Dashboard' })
      }

      return next()
    },
  },
  {
    path: '/',
    component: () => import('../views/Index'),
    beforeEnter(to, from, next) {
      if (!store.getters.isLogged) {
        return next({ name: 'Login' })
      }

      if (!store.getters.isAdmin) {
        store.commit('logout')

        return next({ name: 'Login' })
      }

      return next()
    },
    children: [
      {
        name: 'Dashboard',
        path: '',
        meta: {
          title: 'menu.dashboard',
        },
        component: () => import('@/views/Dashboard/Dashboard'),
      },
      {
        name: 'StoresList',
        path: '/stores/list',
        meta: {
          title: 'menu.stores.list',
        },
        component: () => import('@/views/Stores/List'),
      },
      {
        name: 'StoresAdd',
        path: '/stores/add',
        meta: {
          title: 'menu.stores.add',
        },
        component: () => import('@/views/Stores/Add'),
      },
      {
        name: 'StoresEdit',
        path: '/stores/:id',
        meta: {
          title: 'menu.stores.edit',
        },
        component: () => import('@/views/Stores/Edit'),
      },
      {
        name: 'SellersList',
        path: '/sellers/list',
        meta: {
          title: 'menu.sellers.list',
        },
        component: () => import('@/views/Sellers/List'),
      },
      {
        name: 'SellersEdit',
        path: '/sellers/:id',
        meta: {
          title: 'menu.sellers.edit',
        },
        component: () => import('@/views/Sellers/Edit'),
      },
      {
        name: 'ProductsList',
        path: '/products/list',
        meta: {
          title: 'menu.products.list',
        },
        component: () => import('@/views/Products/List'),
      },
      {
        name: 'ProductsAdd',
        path: '/products/add',
        meta: {
          title: 'menu.products.add',
        },
        component: () => import('@/views/Products/Add'),
      },
      {
        name: 'ProductsEdit',
        path: '/products/:id',
        meta: {
          title: 'menu.products.edit',
        },
        component: () => import('@/views/Products/Edit'),
      },
      {
        name: 'CurationList',
        path: '/curation/list',
        meta: {
          title: 'menu.curation.list',
        },
        component: () => import('@/views/Curation/List'),
      },
      {
        name: 'DropsCalendar',
        path: '/drops/calendar',
        meta: {
          title: 'menu.drops.calendar',
        },
        component: () => import('@/views/Drops/Calendar'),
      },
      {
        name: 'DropsList',
        path: '/drops/list',
        meta: {
          title: 'menu.drops.list',
        },
        component: () => import('@/views/Drops/List'),
      },
      {
        name: 'PriceNegotiationsList',
        path: '/price_negotiations/list',
        meta: {
          title: 'menu.price_negotiations.list',
        },
        component: () => import('@/views/PriceNegotiations/List'),
      },
      {
        name: 'ExternalCatalogProvidersList',
        path: '/external-catalog/providers/list',
        meta: {
          title: 'menu.external_catalog.providers',
        },
        component: () => import('@/views/ExternalCatalog/Providers/List'),
      },
      {
        name: 'ExternalCatalogProvidersEdit',
        path: '/external-catalog/providers/:id',
        meta: {
          title: 'menu.external_catalog.provider_detail',
        },
        component: () => import('@/views/ExternalCatalog/Providers/Edit'),
      },
      {
        name: 'ExternalCatalogSynchroLinesList',
        path: '/external-catalog/synchro-lines/list',
        meta: {
          title: 'menu.external_catalog.list',
        },
        component: () => import('@/views/ExternalCatalog/SynchroLines/List'),
      },
      {
        name: 'ExternalCatalogClassificationsList',
        path: '/external-catalog/classifications/list',
        meta: {
          title: 'menu.external_catalog.classification',
        },
        component: () => import('@/views/ExternalCatalog/Classifications/List'),
      },
      {
        name: 'TrackingList',
        path: '/tracking/list',
        meta: {
          title: 'menu.tracking.list',
        },
        component: () => import('@/views/Tracking/List'),
      },
      {
        name: 'TrackingImport',
        path: '/shipments/tracking/import',
        meta: {
          title: 'menu.tracking.import',
        },
        component: () => import('@/views/Shipments/Import.vue'),
      },
      {
        name: 'ShipmentsList',
        path: '/shipments/list',
        meta: {
          title: 'menu.shipments.list',
        },
        component: () => import('@/views/Shipments/List'),
      },
      {
        name: 'ShipmentsEdit',
        path: '/shipments/:id',
        meta: {
          title: 'menu.shipments.edit',
        },
        component: () => import('@/views/Shipments/Edit'),
      },
      {
        name: 'ReturnsList',
        path: '/returns/list',
        meta: {
          title: 'menu.returns.list',
        },
        component: () => import('@/views/Returns/List'),
      },
      {
        name: 'ReturnsEdit',
        path: '/returns/:id',
        meta: {
          title: 'menu.returns.edit',
        },
        component: () => import('@/views/Returns/Edit'),
      },
      {
        name: 'PhotosList',
        path: '/photos/list',
        meta: {
          title: 'menu.photos.list',
        },
        component: () => import('@/views/Photos/List'),
      },
      {
        name: 'PhotosEdit',
        path: '/photos/:id',
        meta: {
          title: 'menu.photos.edit',
        },
        component: () => import('@/views/Photos/Edit'),
      },
      {
        name: 'AdminTasksList',
        path: '/admin/tasks/list',
        meta: {
          title: 'menu.admin.tasks.list',
        },
        component: () => import('@/views/Admin/Tasks/List'),
      },
      {
        name: 'AdminCategoriesList',
        path: '/admin/categories/list',
        meta: {
          title: 'menu.admin.categories.list',
        },
        component: () => import('@/views/Admin/Categories/List'),
      },
      {
        name: 'AdminCategoriesEdit',
        path: '/admin/categories/:id',
        meta: {
          title: 'menu.admin.categories.edit',
        },
        component: () => import('@/views/Admin/Categories/Edit'),
      },
      {
        name: 'AdminTaxonomiesList',
        path: '/admin/taxonomies/list',
        meta: {
          title: 'menu.admin.taxonomies.list',
        },
        component: () => import('@/views/Admin/Taxonomies/List'),
      },
      {
        name: 'AdminTaxonomiesEdit',
        path: '/admin/taxonomies/:id',
        meta: {
          title: 'menu.admin.taxonomies.edit',
        },
        component: () => import('@/views/Admin/Taxonomies/Edit'),
      },
      {
        name: 'AdminTranslationsList',
        path: '/admin/translations/list',
        meta: {
          title: 'menu.admin.translations.list',
        },
        component: () => import('@/views/Admin/Translations/List'),
      },
    ],
  },
]

const localStorageToken = window.localStorage.getItem('token')
if (
  localStorageToken !== null &&
  localStorageToken !== undefined &&
  localStorageToken !== '' &&
  localStorageToken !== 'null'
) {
  store.commit('login', localStorageToken)
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
