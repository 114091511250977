<template>
  <span>
    <a :href="`/admin/tasks/list?id=${task.id}`">
      <v-icon
        small
        color="blue lighten-1"
      >mdi-link</v-icon>
    </a>
  </span>
</template>

<script>
export default {
  props: {
    task: {
      type: Object,
      required: true,
    },
  },
}
</script>
